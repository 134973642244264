import { Box, Button, Container, LinearProgress, Link, Paper, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useOrder from '../hooks/useOrder';
import { useAuth } from '../contexts/AuthContext';

type Props = {};

const ProcessPayment = (props: Props) => {
	const {useTestMerchant} = useAuth();
	const { orderId } = useParams();
	const { order, isLoading } = useOrder(orderId, useTestMerchant);
	//const [paymentProcessing, setPaymentProcessing] = React.useState(true);
	const navigate = useNavigate();

	if (isLoading) return <></>;

	if (!order)
		return (
			<Container>
				<Box>
					<Typography>Order not found</Typography>
				</Box>
			</Container>
		);

	const paymentProcessing = ['INITIATED', 'RESERVED'].includes(order!.status);

	return (
		<Container>
			<Stack display="flex" height="100%" justifyContent="center" p={1} gap={3}>
					{['INITIATED', 'RESERVED'].includes(order.status) && (
						<>
							<Box sx={{ width: '100%' }} display={paymentProcessing ? 'block' : 'none'}>
								<LinearProgress />
							</Box>
							<Typography>
								Vent her mens vi venter på at betalingen blir behandlet av Vipps. Ikke lukk nettleseren.
							</Typography>
							<Box>Order status {order?.status}</Box>
						</>
					)}
					{order.status === 'CANCELLED' && (
						<>
							Betalingen ble avbrutt. Du har ikke blitt belastet for noe beløp. Du kan prøve igjen ved å
							gå tilbake til <Link href="/store">billettsiden</Link>.
						</>
					)}
					{order.status === 'FAILED' && (
						<>
							Betalingen feilet. Du har ikke blitt belastet for noe beløp. Du kan prøve igjen ved å gå
							tilbake til <Link href="/store">billettsiden</Link>.
						</>
					)}
					{order.status === 'CAPTURED' && (
						<>
							{order.item.type === 'seasonTicket' && 
							<Stack p={1} gap={3}>
								<Box>
								<Typography variant="h5">Takk for kjøp av {order.item.name}</Typography>
								</Box>
								<Box>
									<Typography lineHeight={2} variant="body1">
									En kvittering for kjøpet sendes nå på SMS.
									<br/>
									<br/>
									Vi vil gjerne informere deg om at årskortene dine er klare til henting i bua i bakken. Når du har mottatt kortene, ber vi deg vennligst om å
									aktivere dem. Dette kan enkelt gjøres ved å klikke på "Mine kort" som nå er tilgjengelig i menyen øverst på siden.

 									Vi ser frem til å se deg i bakken!
									</Typography>


								</Box>
								<Button onClick={() => navigate('/')}>Tilbake til hjemmesiden</Button>
							</Stack>}

							{order.item.type === 'dayTicket' &&
							<Stack p={1} gap={3}>
								<Box>
								<Typography variant="h5">Takk for kjøp av {order.item.name}</Typography>
								</Box>
								<Box>
									<Typography lineHeight={2} variant="body1">
									En kvittering sendes nå på SMS.  
									<br/>
									Ved billettkontroll i bakken oppgis kodeordene <Typography variant="h5" color="success">{order?.dayTickets?.codeword} </Typography>til vaktene.
									<br/>
 									Vi ser frem til å se deg i bakken!
									</Typography>

									</Box>
								<Button onClick={() => navigate('/')}>Tilbake til hjemmesiden</Button>
							</Stack>}






						</>
					)}
			</Stack>
		</Container>
	);
};

export default ProcessPayment;
