import React, { useEffect, useState } from "react";
import PublicCalendar from "./PublicCalendar";
import {
	Typography,
	Button,
	Stack,
	Container,
	Checkbox,
	Box,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { db } from "../firebase";

export default function Information() {
	const [isDocOpen, setIsDocOpen] = useState(false);
	const { profile, updateProfile, useTestMerchant } = useAuth();
	const [contacts, setContacts] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchContacts = async () => {
			try {
				const snapshot = await getDocs(collection(db, "contacts"));
				const contactsData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setContacts(contactsData);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching contacts:", error);
				setLoading(false);
			}
		};

		fetchContacts();
	}, []);

	if (loading) {
		return <></>;
	}

	// Use day of week to pick the order of the contacts displayed on the front page
	// Make the algo so that the same contact is not displayed on the same day every week
	const dayOfWeek = new Date().getDay() * 2001 % 7;
	const multiplier = 2346223;
	const startIndex = dayOfWeek * multiplier % contacts.length;
	// get 3 contacts starting from the start index and wrap around if we reach the end of the array
	const contactsToShow = [
		contacts[(startIndex + 0) % contacts.length],
		contacts[(startIndex + 1) % contacts.length],
		contacts[(startIndex + 2) % contacts.length],
	];



	return (
		<>
			<Container>
				<Box sx={{ p: 2 }}>
					<>
						<Stack spacing={2}>
							{(!profile.hasOwnProperty("mandatoryRosters") || profile.mandatoryRosters === 0) && <>
							
							<Box width="95%">
								<Typography variant="h5">
									Kjøp sessongkort
								</Typography>

							</Box>
		
							<Button
								sx={{ maxWidth: 250 }}
								variant="contained"
								component={Link}
								to="/store?showTypes=seasonTicket"
								endIcon={<ShoppingCartIcon />}
							>
								Gå til butikk
							</Button> 
							</>}
							<Box width="95%">
								<Typography variant="h5">
									Vaktkalender
								</Typography>
								<Typography variant="body1">
									I vaktkalenderen kan du registrere og holde
									oversikt over dine vakter. Vi ønsker å ha
									dekning de neste 10 dagene, og oppfordrer
									derfor til å ta første ledige vakt.
									<br />
									<br />
									Åpningstidene som vises på bakolsen.no og
									nederst på denne siden er til en hvert tid
									oppdatert med vaktene som er registrert i
									vaktkalenderen.
								</Typography>
							</Box>
							<Button
								sx={{ maxWidth: 250 }}
								variant="contained"
								component={Link}
								to="/calendar"
							>
								Gå til vaktkalender
							</Button>
							<Box width="95%">
								<Typography variant="h5">
									Vaktopplæring
								</Typography>
								<Typography variant="body1">
									For å sikre en trygg og effektiv drift av
									skiheisen er det påkrevd at all vakter har
									satt seg inn i rutiner og prosedyrer for
									drift av anlegget.
									<br />
									<Checkbox
										checked={profile?.secDocRead ?? false}
										onClick={(e) =>
											updateProfile({
												secDocRead: !(
													profile?.secDocRead ?? false
												),
											})
										}
									></Checkbox>
									Jeg har lest og forstått
									vaktopplæringsdokumentet.{" "}
								</Typography>
							</Box>
							<Button
								sx={{ maxWidth: 250 }}
								variant="contained"
								onClick={() => setIsDocOpen(true)}
							>
								Åpne vaktopplæring
							</Button>
							<Box width="95%">
								<Typography variant="h5">
									Teknisk feil / Spørsmål
								</Typography>
								<Typography variant="body1">
									Hvis du opplever tekniske problemer eller
									har andre spørsmål som haster angående
									anlegget, vennligst kontakt vår tekniske
									gruppe. Vi vil gjøre alt vi kan for å hjelpe
									deg så raskt som mulig. Nedenfor finner du
									dagens kontakter i prioritert rekkefølge:{" "}
									<br />
									<Stack direction="row">
										{contactsToShow.map((p) => (
											<Box
												key={p.name + p.phoneNumber}
												sx={{ m: 2 }}
											>
												{p.name}: {p.phoneNumber}
											</Box>
										))}
									</Stack>
									For andre spørsmål, send epost til{" "}
									<MuiLink href="mailto:post@bakolsen.no">
										post@bakolsen.no
									</MuiLink>
								</Typography>
							</Box>
						</Stack>
					</>

					<Box sx={{ p: 2 }} display="flex" alignItems="center"></Box>
					<Typography
						bgcolor="#aaaaaa"
						color="white"
						textAlign="center"
						variant="h4"
					>
						Åpningstider
					</Typography>
					<PublicCalendar />
				</Box>
			</Container>
			<Popover
				PaperProps={{
					style: { width: "100%", height: "93%" },
				}}
				anchorPosition={{ top: 100, left: 0 }}
				anchorOrigin={{
					vertical: "center",
					horizontal: "center",
				}}
				onClose={() => {
					console.log("Closing popup");
					setIsDocOpen(false);
				}}
				open={isDocOpen}
			>
				<Box height="100%">
					<iframe
						title="learning"
						src="https://onedrive.live.com/embed?cid=AE115B60802CDD92&resid=AE115B60802CDD92%213703&authkey=ANFOCzAfEgb_3Hc&em=2"
						width="99%"
						height="99%"
					></iframe>
				</Box>
			</Popover>
		</>
	);
}
