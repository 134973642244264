import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const RosterToday: React.FC = () => {
	const { profile } = useAuth();

	if (!(profile?.mandatoryRosters > 0))
		return (
			<Container>
				<Box p={2}>
					<Typography>
						Ikke tilgang. Ingen årskort registert på ditt
						telefonnummer
					</Typography>
				</Box>
			</Container>
		);

	return (
		<Container>
			<Stack gap={3} pt={2}>
				<Typography variant="h5">Dagens vakt</Typography>
				<Box>
					Nøkkel til vaktbua ligger i kodeboks til venstre for døra.
					Koden er 8587 (ikke del koden med andre)
				</Box>
				{/* <Box>Utfør kontroll ved å gå gjennom kontrollskjema. </Box>
				<Button
					to="/rosterToday/liftLog"
					component={Link}
					variant="contained"
				>
					Kontrollskjema
				</Button> */}
				<Box> 
					Utfør billettkontroll av dagskort ved å spørre om kodeord og
					sjekke at det stemmer med solgte dagskort ved å gå inn på dagskortoversikt
				</Box>{" "}
				<Button
					to="/rosterToday/control"
					component={Link}
					variant="contained"
				>
					Dagskortoversikt
				</Button>
			</Stack>
		</Container>
	);
};
