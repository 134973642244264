import React, { useContext, useState, useEffect } from "react";
import { auth, loginUserVipps } from "../firebase";
import {Container, LinearProgress} from '@mui/material';
import {
	signInWithCustomToken,
	signOut,
} from "firebase/auth";
import { setDoc, doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import useRosters  from "../hooks/useRosters";


const useTestMerchant = false;

const AuthContext = React.createContext();
const vipps_client_id = "750bab96-9082-49b4-926f-021d6dc2776f";
const vipps_base_url = "https://api.vipps.no";

const vipps_client_id_test = "b6ead6da-8844-41ce-82ed-a22e000cf3af";
const vipps_base_url_test = "https://apitest.vipps.no";

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [profile, setProfile] = useState();
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const {rosterToday} = useRosters(profile);

	const isAdmin = profile?.roles?.admin ?? false;

	function logOut() {
		setCurrentUser();
		setProfile();
		setIsSignedIn(false);
	}

	async function goToVippsSignIn(testMerchant, redirect = true ) {
		//const url = (await client.createSigninRequest()).url;
		const redirect_uri = window.location.origin + "/vippslogin";
		const url = testMerchant ? `${vipps_base_url_test}/access-management-1.0/access/oauth2/auth?client_id=${vipps_client_id_test}&response_type=code&scope=openid%20name%20phoneNumber%20address%20birthDate&state=fc944c7d-d0b3-4181-b6c0-6907a3cc8bb4&redirect_uri=${redirect_uri}` :
		`${vipps_base_url}/access-management-1.0/access/oauth2/auth?client_id=${vipps_client_id}&response_type=code&scope=openid%20name%20phoneNumber%20address%20birthDate&state=fc944c7d-d0b3-4181-b6c0-6907a3cc8bb4&redirect_uri=${redirect_uri}`;

		//const url = 
		//const url = `${vipps_base_url}/access-management-1.0/access/oauth2/auth?client_id=${vipps_client_id}&response_type=code&scope=openid%20name%20phoneNumber%20address%20birthDate&state=fc944c7d-d0b3-4181-b6c0-6907a3cc8bb4&redirect_uri=${redirect_uri}`;
		if (redirect) window.location = url;
		else return url;
	}
	async function loginVipps(code, testMerchant = false) {
		const redirect_uri = window.location.origin + "/vippslogin";
		const res = (await loginUserVipps({code, redirect_uri, testMerchant})).data;
		const authRes = await signInWithCustomToken(auth, res.idToken);
		//console.log(authRes);
	}


	function updateProfile(profile) {
		console.log("Updating profile", profile);
		return setDoc(
			doc(db, "profiles", currentUser.uid),
			{
				...profile,
				updated: new Date(),
			},
			{
				merge: true,
			}
		);
	}




	useEffect(() => {
		// Subscribe to changes to profile
		if (!currentUser) return;

		console.log(
			"Setting up subscription for profile changes",
			currentUser.uid
		);
		const unsubscribe = onSnapshot(
			doc(db, "profiles", currentUser.uid),
			(doc) => {
				if (doc.data()) {
					setProfile({
						...doc.data(),
						uid: currentUser.uid,
					}); // TODO: Clean up the email not in profile but in current user thing. Where should the auth source be?
				
				}
			}
		);

		console.log("AuthContext: Subscribed to profile changes");

		return unsubscribe;
	}, [currentUser]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				//console.log(
				//	"Authcontext:onAuthStateChanged: Setting user updated logic",
				//	user
				//);
				setCurrentUser(user);
				// Load user profile from firestore
				/* auth.currentUser.getIdTokenResult().then((idTokenResult) => {
					console.log("IdTokenResult: ", idTokenResult);

					if (idTokenResult.claims.role === "admin") {
						setIsAdmin(true);
					}
				}); */

				console.log("Loading profile");
				try {
					//console.log("Getting docref");
					const docRef = doc(db, "profiles", user.uid);
					//console.log("DOCREF:",docRef);
					
					const prof = await getDoc(docRef);
					//console.log("Loaded profile: ", prof);
					if (prof.exists()) {
						//console.log("Setting profile", prof.data());
						setProfile({
							...prof.data(),
							uid: user.uid,
						});
					}
					setIsSignedIn(true);
					setIsLoading(false);
					// Navigate to profile page if profile is not complete
						
				} catch (error) {
					console.log("Error loading profile", error);
					signOut(auth);
					setIsSignedIn(false);
					setIsLoading(false);
				}
			} else {
				console.log("Not logged in");
				setIsSignedIn(false);
				setIsLoading(false);
			}
		});
		return unsubscribe;
	}, []);

	const value = {
		auth,
		logOut,
		currentUser,
		rosterToday,
		useTestMerchant,
		isAdmin,
		profile,
		isSignedIn,
		isLoading,
		updateProfile,
		goToVippsSignIn,
		loginVipps,
	};


	if (isLoading) 
	return (
		<Container>
			<LinearProgress /> {/* Add the circular progress component here */}
		</Container>
	);


	return (
		<AuthContext.Provider value={value}> {children} </AuthContext.Provider>
	);
}
