import Grid from "@mui/material/Grid";
import CalendarDay from "./CalendarDay";
import Button from "@mui/material/Button";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import dayjs from "dayjs";
import useCalendar from "../hooks/useCalendar";
import { useAuth } from "../contexts/AuthContext";

export default function Calendar({numberOfDays=12, DayComponent=CalendarDay, users}) {
    const {rosterCalendar, viewStartDate, setViewStartDate, addRoster, deleteRoster, closeDay,  deleteOverride, insertOverride, isLoading} = useCalendar(dayjs(), numberOfDays);
    const {isAdmin} = useAuth();

    /* TODO - move all stuff into roster calendar and the day object */

    const DateNavButtons = () => {
        return (
            <Grid item xs={12}  justify="center" textAlign="center">
                    <Button color="primary" key="a" onClick={() => setViewStartDate(viewStartDate.add(-numberOfDays,'day'))}><SkipPreviousIcon />Forrige</Button>
                    <Button key="b" onClick={() => setViewStartDate(dayjs())}>I dag</Button>
                    <Button key="c" onClick={() => setViewStartDate(viewStartDate.add(numberOfDays,'day'))}>Neste <SkipNextIcon/></Button>                
            </Grid>
        )
    }

    return(<>
        <Grid container spacing={2}>
            <DateNavButtons/>  
            {!isLoading && rosterCalendar?.getDays().map((d,i) => (
                <DayComponent users={users} key={"day"+i} day={d} insertOverride={insertOverride} showControls={isAdmin} addRosterHandler={addRoster} deleteRosterHandler={deleteRoster} closeDayHandler={closeDay} deleteOverride={deleteOverride}/>
            ))}
            <DateNavButtons/>
        </Grid>
    </>);
}