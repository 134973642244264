import { Button, Container, Stack, Typography } from "@mui/material";


export const LiftLog: React.FC = () => {
	return (
		<Container>
			<Typography variant="h5">Daglig kontroll</Typography>
			Følgende punkter er kontrollert før oppstart av 1. vakt:
			<form>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Button type="submit" color="success" variant="contained">
						Lagre
					</Button>
					<Button variant="contained">Avbryt</Button>
				</Stack>
			</form>
		</Container>
	);
};
