import { useEffect, useState } from "react";
import { query, collection, where, onSnapshot} from "firebase/firestore";
import {db} from "../firebase";
import dayjs from "dayjs";

const useRosters = (profile:any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [rosters, setRosters] = useState([]);
    const [rosterToday, setRosterToday] = useState(false);

    
    useEffect(() => {
        if (!profile) return;
        const q = query(collection(db, "rosters"), where("assignedToPhoneNumber", "==", profile.phoneNumber), where("date", ">=", dayjs().add(-6,"month").toDate()));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const rosters = [];
          querySnapshot.forEach((doc) => {
              console.log(doc.data());
              rosters.push({...doc.data(),id:doc.id, date:dayjs(doc.data().date.seconds*1000)});
          });
          rosters.sort((a,b) => a.date - b.date);
          setRosters(rosters); 
          setIsLoading(false);
        });
        return unsubscribe;
    },[profile])


	useEffect (() => {
		// Check if any of the rosters are for today
		if (!profile) return;
		if (isLoading) return;
		if (!rosters) return;
		const today = dayjs();
        const todayString = today.format("YYYY-MM-DD");

		const todaysRoster = rosters.find((r:any) => r.date.format("YYYY-MM-DD") === todayString);
		todaysRoster ? setRosterToday(true) : setRosterToday(false);
		
	}, [profile, isLoading, rosters]);		


    return {isLoading, rosters, rosterToday}
}





export default useRosters;