import { Box, Container, Divider, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCollectionGlobal } from "../hooks/useFirestoreCollection";
import { and, where } from "firebase/firestore";
import dayjs from "dayjs";

export const TicketControl: React.FC = () => {
    const navigate = useNavigate();
    const {docs:tickets} = useCollectionGlobal("dayTickets", and(where("created", ">", dayjs().startOf("day").toDate()), where("created", "<", dayjs().endOf("day").toDate())));

    
	return (
		<Container>
			<Box pt={2}>
                <Box display="flex" justifyContent={"end"}><IconButton onClick={() => navigate("/rosterToday")}><CloseIcon/></IconButton></Box>

                <Typography variant="h5">Dagens billetter</Typography>
                <Divider/>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Kodeord</TableCell>
                        <TableCell>tlf</TableCell>
                        <TableCell align="center">Antall</TableCell>
                        </TableRow>

                        </TableHead>
                    <TableBody>
                    {tickets.map((ticket:any) => { 
                        return <TableRow key={ticket.id}>
                            <TableCell>{ticket.codeword}</TableCell>
                            <TableCell>****{ticket.mobileNumber.slice(4)}</TableCell>
                            <TableCell align="center">{ticket.numTickets}</TableCell>
                        </TableRow>
                    }   
                    )}
                    
                    </TableBody>
                </Table>



            </Box>
		</Container>
	);
};
