import {
  initializeApp
} from "firebase/app";
import {
  getApp
} from "firebase/app";
import {
  getAuth,
  connectAuthEmulator
} from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator
} from "firebase/firestore";
import {
  getFunctions,
  connectFunctionsEmulator
} from "firebase/functions"
import {
  httpsCallable
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";


const config = {
  apiKey: "AIzaSyAjpUUeV1HHyuyNRk-Idn2GYQlEk62OCks",
  authDomain: "bak-olsen.firebaseapp.com",
  projectId: "bak-olsen",
  storageBucket: "bak-olsen.appspot.com",
  messagingSenderId: "803479444110",
  appId: "1:803479444110:web:da99284fb6a6a992a087df",
  measurementId: "G-0G2SQ8QXTQ"
}


const EMULATORS = false;

initializeApp(config);
const auth = getAuth();
const db = getFirestore();
const functions = getFunctions(getApp(), "europe-west1");
const analytics = getAnalytics(getApp());

if (window.location.host === "localhost:3000" && EMULATORS ) {
  console.log("Using LOCAL EUMULATOR!")
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectFunctionsEmulator(functions, "localhost", 5001)
}

const importUsersCSV = httpsCallable(functions, 'importUsersCSV');
const isRegisteredUser = httpsCallable(functions, 'isRegisteredUser');
const loginUserVipps = httpsCallable(functions, 'loginUserVipps');
const initiatePayment = httpsCallable(functions, 'initiatePayment');
const captureOrderPoll = httpsCallable(functions, 'captureOrderPoll');


export {
  auth,
  db,
  importUsersCSV,
  isRegisteredUser,
  loginUserVipps,
  initiatePayment,
  captureOrderPoll,
  analytics
};

//const analytics = getAnalytics(app);