import {
	Box,
	Button,
	Container,
	Dialog,
	FormControl,
	InputLabel,
	LinearProgress,
	List,
	ListItem,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, initiatePayment } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import Terms from "./Terms";
import Checkbox from "@mui/material/Checkbox";

type Props = {};

const Tickets = (props: Props) => {
	const { useTestMerchant, profile } = useAuth();
	const [selectedItemId, setSelectedItemId] = React.useState<string>();
	const [numTickets, setNumTickets] = useState(1);
	const [mobileNumber, setMobileNumber] = React.useState(
		useTestMerchant ? "96682809" : profile?.phoneNumber.slice(2)
	);
	const [inProgress, setInProgress] = React.useState(false);
	const [error, setError] = React.useState("");
	const [storeItems, setStoreItems] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);

	const [termsOpen, setTermsOpen] = React.useState(false);
	// Read url parameters
	const showTypes = new URLSearchParams(window.location.search).get(
		"showTypes"
	);
	const [showTicketTypes, setTicketTypes] = React.useState(
		showTypes ? showTypes.split(",") : []
	);

	useEffect(() => {
		if (profile) {
			setMobileNumber(profile.phoneNumber.slice(2));
		}
	}, [profile]);

	useEffect(() => {
		// Create a unique id for the store item and store the ID in session storage
		const ss_uid = sessionStorage.getItem("s_uid");
		if (ss_uid && ss_uid.length >= 7) {
			setSelectedItemId(ss_uid);
		}
	}, []);

	useEffect(() => {
		const fetchStoreItems = async () => {
			// Fetch all data from firestore table "store"
			const data = await getDocs(collection(db, "store"));
			const items = data.docs.map(
				(doc) =>
					({
						id: doc.id,
						...doc.data(),
					} as any)
			);

			const filteredItems = items.filter((item) =>
				showTicketTypes.includes(item.type)
			);
			filteredItems.sort((a, b) => (a.price > b.price ? 1 : -1));

			setStoreItems(filteredItems);
			if (filteredItems.length > 0)
				setSelectedItemId(filteredItems[0].id);

			setLoading(false);
		};
		fetchStoreItems();
	}, [showTicketTypes]);

	const handleClickPay = async () => {
		setInProgress(true);

		try {
			const result: any = await initiatePayment({
				itemId: selectedItemId,
				numTickets: numTickets,
				mobileNumber,
				fallbackPrefix: `${window.location.origin}/processpayment`,
				testMerchant: useTestMerchant,
				useLocalCallback: window.location.host === "localhost:3000",
			});

			const response = result.data;

			if (response.code !== "OK") {
				// handle error
				console.log("Error: ", result);
				setError("Error: " + result.data.message);
				return;
			} else {
				// Redrect to Vipps payment page
				//setRedirectUrl(result.data.redirectUrl);
				window.location.replace(response.redirectUrl);
				// use router and navigate to redirectUrl
			}
		} catch (error) {
		} finally {
			setInProgress(false);
		}
	};

	const SeasonPass = () => {
		return (
			<Stack pt={2} gap={2}>
				<Typography variant="h5">Sesongkort</Typography>
				<br />
				Vi selger sesongkort etter et familievennlig prinsipp, men med
				obligatoriske vakter av en voksenperson i hustanden, se
				nedenfor:
				<List>
					{storeItems?.map((item) => (
						<ListItem>{item.description}</ListItem>
					))}
				</List>
				<Box>
					<FormControl>
						<InputLabel id="num-tickets">
							Velg sesongkort
						</InputLabel>

						<Box sx={{ p: 2 }}>
							<Select
								id="subscription-type"
								value={selectedItemId}
								label="Antall"
								onChange={(e) =>
									setSelectedItemId(e.target.value)
								}
							>
								{storeItems?.map((item) => (
									<MenuItem value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</Box>
					</FormControl>
				</Box>
			</Stack>
		);
	};

	// Remaining code...
	if (loading) return <></>;

	console.log("showTicketTypes", showTicketTypes);

	return (
		<Container>
			{showTicketTypes.includes("dayTicket") ? (
				<DayPasses
					storeItems={storeItems}
					itemId={selectedItemId}
					itemChanged={(id, numTickets) => {
						setSelectedItemId(id);
						setNumTickets(numTickets);
					}}
				/>
			) : (
				<SeasonPass />
			)}
			<Stack m={2} gap={3} display="flex">
				<Box>
					<FormControl>
						<PhoneNumberInput
							mobileNumber={mobileNumber}
							onChange={(number) => setMobileNumber(number)}
						/>
					</FormControl>
				</Box>
				<Box>
					Ved kjøp av kort i Bak-Olsen godtar jeg{" "}
					<a href="#" onClick={() => setTermsOpen(true)}>
						salgsvilkårene
					</a>{" "}
					for Bak-Olsen skitrekk.
				</Box>

				<Typography>{error}</Typography>
				{inProgress && <LinearProgress />}

				<Box
					sx={{
						m: 1,
						position: "relative",
						display: "flex",
						justifyContent: "center",
					}}
				>
					
					<Button disabled={inProgress} onClick={handleClickPay}>
						<img
							src="/images/paywithvipps.svg"
							alt="Betal med Vipps"
						/>
					</Button>
				</Box>
			</Stack>

			<Dialog open={termsOpen} onClose={() => setTermsOpen(false)}>
						<Terms onClose={() => setTermsOpen(false)} />
			</Dialog>
		</Container>
	);
};

const DayPasses: React.FC<{
	storeItems: any[];
	itemId: string | undefined;
	itemChanged: (id: string, numTickets: number) => void;
}> = ({ storeItems, itemId, itemChanged }) => {
	const [numTickets, setNumTickets] = useState(1);

	const handleNumTicketsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value);
		if (value >= 1 && value <= 6) {
			setNumTickets(value);
			itemChanged(itemId!, value);
		}
	};

	return (
		<Box pt={2}>
			<FormControl>
				<Stack m={2} gap={3}>
					<Box>
						<Typography variant="h5">Kjøp dagskort</Typography>
					</Box>

					<Stack
						direction="row"
						sx={{ border: 1, color: "grey", borderRadius: 2 }}
					>
						{storeItems?.map((item) => (
							<>
								<Box
									alignItems="center"
									justifyContent={"space-between"}
									display="flex"
									flexGrow={1}
									key={item.id}
								>
									<Stack
										direction="row"
										alignItems="center"
										gap={2}
									>
										<Checkbox
											checked={itemId === item.id}
										/>
										{item.description}
									</Stack>
									<Box pr={2}>{item.price} kr x</Box>
								</Box>
								<TextField
									//type="number"
									select
									//label="Antall"
									//variant="filled"
									value={numTickets}
									defaultValue={1}
									onChange={handleNumTicketsChange}
								>
									{[1, 2, 3, 4, 5, 6].map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</>
						))}
					</Stack>

					<Box>
						Etter betaling er gjennomført vil du få en SMS med en
						ordkode (f.eks MAGISK DRØM) som kan oppgis til vaktene
						når det er billettkontroll i bakken.
					</Box>
				</Stack>
			</FormControl>
		</Box>
	);
};

const PhoneNumberInput: React.FC<{
	mobileNumber: string;
	onChange: (number: string, hasError: boolean) => void;
}> = ({ mobileNumber, onChange }) => {
	const [numberError, setPhoneNumberError] = useState(false);
	const [errorText, setPhoneNumberErrorText] = useState("");

	const handlePhoneNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.target.value.slice(0, 8);
		onChange(value, numberError);

		// Check if input has any non-numeric characters

		if (value.length >= 8) {
			if (/^\d{8}$/.test(value)) {
				setPhoneNumberError(false);
			} else {
				setPhoneNumberError(true);
				//setPhoneNumberErrorText("Ikke gyldig telefonnummer");
			}
		} else {
			setPhoneNumberError(false);
			//setPhoneNumberErrorText("");
		}
	};

	return (
		<TextField
			id="mobilePhone"
			label="Telefonnummer"
			value={mobileNumber}
			autoComplete="tel"
			focused={true}
			sx={{ width: 120 }}
			onChange={handlePhoneNumberChange}
			error={numberError}
			helperText={errorText}
		/>
	);
};

export default Tickets;
