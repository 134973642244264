import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import useCalendar from "../hooks/useCalendar";
import { reasonTranslator } from "../shared/closedReasonTranslator";

export default function PublicCalendar({ numberOfDays = 7 }) {
	const { rosterCalendar, isLoading } = useCalendar(dayjs(), numberOfDays);

	console.log("Comp PublicCalendar: Roster calendar", rosterCalendar);
	console.log("Comp PublicCalendar: IsLoading", isLoading);

	return (
		<>
			<Container>
				<Box sx={{ flexGrow: 1 }}>
					<Stack direction="column">
						<Box
							sx={{
								textTransform: "capitalize",
								fontWeight: "medium",
								fontSize: "h6.fontSize",
							}}
						>
							<Table>
								<TableBody>
									{!isLoading &&
										rosterCalendar
											?.getDays()
											.map((d, i) => {

												const slots = d.getSlots();
												// get slots that has sufficient bookings
												//console.log("Shwoing slots", slots, d);
												const filteredSlots = slots.filter((slot)=> d.showAsOpen(slot) )
												//console.log("Filtered slots", filteredSlots);

												return (
													<TableRow key={i}>
														<TableCell align="right">
															<Box
																sx={{
																	textTransform:
																		"capitalize",
																}}
															>
																{d.date.format(
																	"dddd DD.MM"
																)}
															</Box>
														</TableCell>
														<TableCell align="left">
															{d.isOpen() && 
																d.isSufficientlyBooked() &&
																`${
																	d.getOpeningHours()[0]
																}-${
																	d.getOpeningHours()[1]
																}`}

															{filteredSlots.length > 0 && !d.isSufficientlyBooked() && filteredSlots.map((slot, i) => (	

																slot.start + "-" + slot.end))
															}

															{d.isOpen() &&
																filteredSlots.length == 0  &&
																"Foreløpig Stengt (Mangler vakt)"}
															{!d.isOpen() &&
																reasonTranslator[
																	d.getClosedReason()
																		? d.getClosedReason()
																		: "closed"
																]}
														</TableCell>
													</TableRow>
												);
											})}
								</TableBody>
							</Table>
						</Box>
					</Stack>
				</Box>
			</Container>
		</>
	);
}
