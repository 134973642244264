import {
    useState,
    useEffect
} from "react";
import RosterCalendar from "../shared/RosterCalendar";
import {
    query,
    deleteDoc,
    addDoc,
    doc,
    collection,
    where,
    onSnapshot
} from "firebase/firestore";
import {
    db
} from "../firebase";
import dayjs from "dayjs";
import {
    useAuth
} from "../contexts/AuthContext";

const useCalendar = (date, numDays) => {
    const [rosterCalendar, setRosterCalendar] = useState(RosterCalendar(date, numDays));
    const [isLoading, setIsLoading] = useState(true);
    const [viewStartDate, setViewStartDate] = useState(date);
    const [numberOfDays, setNumberOfDays] = useState(numDays);
    const {
        currentUser,
        profile
    } = useAuth();


    const dateQuery = (startDate, numberOfDays, collectionName) => {
        const q = query(collection(db, collectionName),
            where("date", ">=", startDate.add(-1, "day").toDate()),
            where("date", "<", startDate.add(numberOfDays, "day").toDate()));
        return q;
    }


    dayjs.locale('nb');

    useEffect(() => {

        const subscribeToRosters = (start, days, cal) => {

            const q = dateQuery(start, days, "rosters");
            const unsubscribe = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    console.log("GOT A CHANGE");
                    if (change.type === "added") {
                        cal.insertRoster({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                    if (change.type === "removed") {
                        cal.removeRoster({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                });

                setIsLoading(false);
                setRosterCalendar({
                    ...cal
                });
            });

            return unsubscribe;

        }

        const subscribeToDayOverrides = (start, days, cal) => {

            const q = dateQuery(start, days, "dayoverrides");
            const unsubscribe = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        cal.insertDayOverride({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                    if (change.type === "removed") {
                        cal.removeDayOverride({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                });

                setRosterCalendar({
                    ...cal
                });
            });

            return unsubscribe;

        }

        console.log("Start date or numberOfDays changed, created new rosterCalendar");
        const rCalendar = RosterCalendar(viewStartDate, numberOfDays);
        /* Update subscriptions to updated calendar */
        const overrideUnsub = subscribeToDayOverrides(viewStartDate, numberOfDays, rCalendar);
        const rostersUnsub = subscribeToRosters(viewStartDate, numberOfDays, rCalendar);

        setRosterCalendar(rCalendar);

        return (() => {
            rostersUnsub();
            overrideUnsub();
        });

    }, [viewStartDate, numberOfDays]);



    const capitalize = (s) => {
        return `${s[0].toUpperCase()}${s.slice(1)}`
    }

    function deleteRoster(roster) {
        console.log("Delete Roster", roster)
        deleteDoc(doc(db, "rosters", roster.id))
            .then((result) => {})
            .catch(error => {
                console.error("Unable to delete roster");
            })
    }

    /* TODO: move to useMyRosters */
    function addRoster(date, slot, forUser) {
        if (!forUser) forUser = profile;

        console.log("ADDING", date, slot);

        const docData = {
            mappedToPhone: forUser.phoneNumber,
            assignedToEmail: forUser.email,
            assignedToFirstName: forUser.firstName,
            assignedToLastName: forUser.lastName,
            assignedToPhoneNumber: forUser.phoneNumber,
            updatedBy: currentUser.uid,
            updatedByEmail: currentUser.email,
            updatedByFirstName: profile.firstName,
            updatedByLastName: profile.lastName,
            date: date.toDate(),
            start: slot.start,
            end: slot.end
            }

        addDoc(collection(db, "rosters"), docData)
            .then((result) => {
                console.log(result);
            })
    }

    function insertOverride(day, override) {
        console.log("insertOverride", day.getId());
        if (day.getId()) deleteOverride(day);
        addDoc(collection(db, "dayoverrides"), {
                date: day.date.toDate(),
                ...override
            })
            .then((result) => {})
            .catch(error => {
                console.error("Unable to add override");
            })
    }

    function deleteOverride(day) {
        console.log("Delete override with ID ", day.getId());
        deleteDoc(doc(db, "dayoverrides", day.getId()))
            .then((result) => {})
            .catch(error => {
                console.error("Unable to delete override");
            })

    }

    async function closeDay(day, reason) {
        console.log("closing", day, reason, day.getAllRosters())

        const dayStr = capitalize(day.date.format("dddd DD. MMMM")); //toLocaleDateString("no-nb", {weekday:"long", month:"long", day: 'numeric'}));

        const recipients = Array.from(new Set(day.getAllRosters().map(roster => (roster.assignedToPhoneNumber))
            .filter(roster => roster))).join(",");

        console.log(recipients);

        day.getAllRosters().forEach(roster => {
            console.log("Roster to be removed: ", roster);
            deleteRoster(roster);
        })



        const closeData = {
            date: day.date.toDate(),
            isOpen: false,
            reason: reason
        }

        addDoc(collection(db, "dayoverrides"), closeData)
            .then((result) => {
                console.log("Added close day to db");
            })

        const docData = {
            type: "SMS",
            messageText: `Bakken er meldt stengt ${dayStr} av Bak-Olsen grunnet dårlig vær`,
            recipients: recipients
        }
        return addDoc(collection(db, "messageOutbox"), docData);

    }

    /*  function addDays(d, days) {
          const newDate = new Date(d.valueOf());
          newDate.setDate(newDate.getDate() + days);
          return(newDate);
      } */



    return {
        rosterCalendar,
        viewStartDate,
        setViewStartDate,
        setNumberOfDays,
        addRoster,
        deleteRoster,
        closeDay,
        insertOverride,
        deleteOverride,
        isLoading
    }

};

export default useCalendar;