import {
	Container,
	Box,
	Grid,
	Button,

} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import PublicCalendar from "./PublicCalendar";
import { Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router";

export default function Information() {
	const { goToVippsSignIn, useTestMerchant} = useAuth();
	const navigate = useNavigate();

	const handleLoginVipps = () => {
		// read testMerchange from session storage
		if (useTestMerchant) {
			goToVippsSignIn(true);
		} else {
			goToVippsSignIn(false);
		}
	};

	return (
		<Container>
			<Box sx={{ p: 2 }}>
				<Box sx={{ display: "flex", alignItems: "center", p: 0 }}>
					<Grid container spacing={2}>
						<Grid item sm={12} md={6}>
							<Typography variant="h6">
								Sesongkort og vaktinnlogging
							</Typography>
							Velg logg inn med Vipps for å kjøpe sesongkort eller administrere dine vakter.
						</Grid>
						<Grid item sm={12} md={6}>
							<Box display="flex" alignItems="center">
								<img
									onClick={handleLoginVipps}
									alt="Logg inn med Vipps"
									src={"images/LoginVippsNO.png"}
									//src="/loginVippsNO.png"
									style={{ cursor: "pointer" }}
								/>
							</Box>
						</Grid>
						<Grid item sm={12} md={6}>
							<Typography variant="h6">Dagskort</Typography>
							Dagskort kan kjøpes i vår nettbutikk
						</Grid>
						<Grid item sm={12} md={6}>
							<Box display="flex" alignItems="center">
								<Button
									sx={{ width: "214px" }}
									variant="outlined"
									//href="/store?showTypes=dayTicket"
									onClick={() => navigate("/store?showTypes=dayTicket")}
									endIcon={<ShoppingCartIcon />}
								>
									Gå til butikk
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box sx={{ p: 2 }} display="flex" alignItems="center"></Box>
				<Typography
					bgcolor="#aaaaaa"
					color="white"
					textAlign="center"
					variant="h4"
				>
					Åpningstider
				</Typography>
				<PublicCalendar />
			</Box>
		</Container>
	);
}
