import React from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

export const Terms:React.FC<{onClose:() => void}> = ({onClose}) => {
	return (
			<Box overflow="scroll">

				<Typography>
					<Stack direction="row" display="flex" justifyContent="center" alignContent="space-between" spacing={2}>
					<h1>Salgsvilkår for Bak-Olsen skitrekk</h1>
					<Button onClick={onClose}>Lukk</Button>
					</Stack>
					<ol>
						<li>
							<h2>Parter</h2>
							<p>
								Disse salgsvilkårene regulerer avtalen mellom Bak-Olsen skitrekk (heretter kalt "selger") og
								kjøperen (heretter kalt "kunden") for salg av dagskort eller årskort på vakt.bakolsen.no. Ved å
								fullføre en bestilling på vakt.bakolsen.no, godtar kunden disse salgsvilkårene.
							</p>
						</li>
						<li>
							<h2>Betaling</h2>
							<p>
								Selger godtar kun betaling gjennom Vipps. Ved betaling vil kunden motta en bekreftelse på
								bestillingen via SMS.
							</p>
						</li>
						<li>
							<h2>Levering</h2>
							<p>
								Etter betaling vil billetten(e) være tilgjengelig i selgers system på vakt.bakolsen.no. Selger er ikke ansvarlig
								for eventuelle tekniske problemer som kan hindre levering av billetter/sessonkort.
							</p>
						</li>
						<li>
							<h2>Angrerett</h2>
							<p>
								Billettene/Sesongkortene til Bak-Olsen skitrekk er ikke refunderbare. Kunden kan ikke angre på kjøpet etter at billettene er kjøpt og betalt for.
							</p>
						</li>
						<li>
							<h2>Retur</h2>
							<p>Selger aksepterer ikke retur av billetter/sesongkort.</p>
						</li>
						<li>
							<h2>Reklamasjonshåndtering</h2>
							<p>
								Dersom kunden mener det er feil på billettene/sessonkortene, eller de ikke fungerer, må kunden ta
								kontakt med Bak-Olsen skitrekk umiddelbart via e-post til{' '}
								<a href="mailto:post@bakolsen.no">kundeservice</a>. Reklamasjoner vil bli behandlet
								så raskt som mulig.
							</p>
						</li>
						<li>
							<h2>Konfliktløsning</h2>
							<p>
								Dersom det skulle oppstå en tvist mellom selger og kunden, skal partene først forsøke å
								løse tvisten i minnelighet. Dersom dette ikke er mulig, kan tvisten bringes inn for de
								ordinære domstolene etter norsk rett.
							</p>
						</li>
					</ol>
					<p>
						Disse salgsvilkårene kan endres av Bak-Olsen skitrekk uten varsel til kunden. Kunden oppfordres til å
						lese salgsvilkårene nøye før de fullfører en bestilling på vakt.bakolsen.no.
					</p>
				</Typography>
			</Box>
	);
};

export default Terms;
