import { deleteDoc, doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import Calendar from "./Calendar";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import dayjs from "dayjs";
import useRosters from "../hooks/useRosters";

export default function UserCalendar() {
	const { profile } = useAuth();
	const { isLoading: rostersLoading, rosters: myRosters } =
		useRosters(profile);
	//const [showConfetti, setShowConfetti] = useState(false);

	function deleteRoster(roster) {
		deleteDoc(doc(db, "rosters", roster.id)).then((result) => {});
	}

	if (!profile?.firstName || !profile?.lastName || !profile.email)
		return (
			<Container>
				<Typography variant="h6">
					Du må legge inn navn og telefonnummer i profilen før du kan
					registrere vakter. Gå <Link to="/profile">hit</Link> for å
					gjøre det
				</Typography>
			</Container>
		);

	const MyRosters = () => {
		if (rostersLoading) return <></>;

		const RemainingRosters = profile?.mandatoryRosters
			? profile.mandatoryRosters - myRosters.length
			: 0;
		let userMessage;

		if (!profile?.mandatoryRosters) {
			userMessage = (
				<>
					{/*Ingen årskort registrert på din bruker {profile.email}.
					Oppdater <Link to="/profile">profilen</Link> din hvis du vil
					registrere vakter på vegne av noen andre. Ta kontakt med
			post@bakolsen.no dersom du har spørsmål. */}
				</>
			);
		} else if (RemainingRosters > 0) {
			userMessage = `Du har ${RemainingRosters} gjenværende obligatoriske vakter.`;
		} else if (RemainingRosters === 0) {
			userMessage = `Du har registrert alle dine ${profile.mandatoryRosters} obligatoriske vakter. Tusen takk! Ta gjerne flere om du vil!`;
		} else if (RemainingRosters < 0) {
			userMessage = `Du har registrert ${
				myRosters.length - profile.mandatoryRosters
			} flere enn dine ${
				profile.mandatoryRosters
			} obligatoriske vakter. Tusen hjertelig takk!`;
		}

		/* if (RemainingRosters <= 0) {
            import('canvas-confetti').then(module => {
                const confetti = module.default;
                confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: { y: 0.6 }
                  });
            })
        } */

		return (
			<>
				<Container>
					<Grid container spacing={1}>
						{myRosters
							.filter(
								(roster) =>
									!roster.date.isBefore(
										dayjs().add(-1, "day")
									)
							)
							.map((roster) => (
								<Grid item>
									<Chip
										key={roster.id}
										size="medium"
										color="success"
										deleteIcon={<DeleteIcon />}
										label={
											roster.date.format(
												"dddd DD. MMMM "
											) +
											roster.start +
											"-" +
											roster.end
										}
										onDelete={() => deleteRoster(roster)}
									/>
								</Grid>
							))}

						{myRosters
							.filter((roster) =>
								roster.date.isBefore(dayjs().add(-1, "day"))
							)
							.map((roster) => (
								<Grid item>
									<Typography>
										<Chip
											key={roster.id}
											size="medium"
											color="primary"
											variant="outlined"
											label={
												"Utført " +
												roster.date.format(
													"dddd DD. MMMM "
												) +
												roster.start +
												"-" +
												roster.end
											}
										/>
									</Typography>
								</Grid>
							))}
					</Grid>
				</Container>

				<Container sx={{ m: "0.2cm" }}>
					<Box>{userMessage}</Box>
				</Container>
			</>
		);
	};

	return (
		<>
			<Container>
				<Divider textAlign="left" sx={{ my: 2 }}>
					<Typography variant="h6">Mine Vakter</Typography>
				</Divider>

				<MyRosters />

				<Calendar showControls />
			</Container>
		</>
	);
}
