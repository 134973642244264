import {
	Container,
	Box,
	Grid,

} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import PublicCalendar from "./PublicCalendar";
import { Typography } from "@mui/material";

export default function Activate() {


	return (
		<Container>
			<Box sx={{ p: 2 }}>
				
             Kommer snart: Her kan du aktivere sessongkortene dine.
					
			</Box>
		</Container>
	);
}
